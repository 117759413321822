import { observer } from 'mobx-react-lite';
import { screen } from './types/enums';
import State from './data/State';
import Loading from './screens/Loading';
import Error from './screens/Error';
import Main from './screens/Main';
import Rules1 from './screens/Rules1';
import Rules2 from './screens/Rules2';
import Rules3 from './screens/Rules3';
import Ready from './screens/Ready';
import Round1 from './screens/Round1';
import Round2 from './screens/Round2';
import Round3 from './screens/Round3';
import Result from './screens/Result';
import Ratings from './screens/Ratings';
import Question from './screens/Question';
import bridge from '@vkontakte/vk-bridge';
import { useEffect, useState } from 'react';

export default observer(() => {
  return <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100vh',
      color: '#FFFFFF',
      fontSize: 30
    }}>
    Игра доступна только с мобильных устройств
  </div>
});