import './css/styles.css';
import './types/interfaces';
import ReactDOM from 'react-dom/client';
import bridge from '@vkontakte/vk-bridge';
import App from './App';
import State from './data/State';

bridge.send('VKWebAppInit');
ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
).render(<App />);

window.onclick = () => State.setInteractive();
window.onfocus = () => State.setInteractive();
document.addEventListener('keydown', () => State.setInteractive());