import { makeAutoObservable, runInAction } from 'mobx';
import { error, screen } from '../types/enums';

class State {
  constructor() {
    makeAutoObservable(this);
    setInterval((): void => {
      runInAction((): void => {
        if (this._superQuestion) {
          this._superQuestion.start--;
          this._superQuestion.end--;
        }
      });
    }, 1000);
  }

  public sound: HTMLAudioElement;
  public readonly MAX_QUESTIONS_1_ROUND = 10;
  private _screen: screen = screen.LOADING;
  private _loading: number = 0;
  private _error: error = null;
  private _superQuestion: IsuperQuestion;
  private _interactive: boolean = false;
  private _loaded: boolean = false;
  public sounds = {
    button: null,
    rules1: null,
    rules2: null,
    rightAnswer: null,
    wrongAnswer: null,
    mainSound: null,
    round3: null,
    timer: null
  }

  public getScreen(): screen {
    return this._screen;
  }

  public setScreen(page: screen): void {
    if (page !== screen.RATINGS) {
      this.sound?.pause();
      this.sound && (this.sound.loop = false);
    }
    this._screen = page;
  }

  public getLoading(): number {
    return this._loading;
  }

  public setLoading(loading: number): void {
    this._loading = loading;
  }

  public getError(): error {
    return this._error;
  }

  public setError(error: error): void {
    this._error = error;
  }

  public getSuperQuestion(): IsuperQuestion {
    return this._superQuestion;
  }

  public setSuperQuestion(question: IsuperQuestion): void {
    this._superQuestion = question;
  }

  public getInteractive(): boolean {
    return this._interactive;
  }

  public setInteractive(): void {
    this._interactive = true;
  }

  public playSound(key: string): void {
    const audio = this.sounds[key]
    audio.currentTime = 0;
    audio.play();
  }

  public getLoaded(): boolean {
    return this._loaded;
  }

  public setLoaded(loaded: boolean): void {
    this._loaded = loaded;
  }
}

export default new State();